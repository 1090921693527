body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.container {
  display: flex;
  height: 100vh; /* Use viewport height to fill the entire screen height */
  width: 100vw; /* Use viewport width to fill the entire screen width */
}

.left-section, .right-section {
  height: 100%; /* Ensure full height */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left-section {
  flex: 2; /* Adjust the ratio to your liking */
  background-color: #282a36;
  color: black;
  padding: 20px;
}

.right-section {
  flex: 1;
  background-color: #00BABC;
  color: white;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  padding: 20px;
}

.logo-placeholder {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}

.verification-process {
  max-width: 80%;
}

.upload-container {
  background-color: #FFF;
  width: auto; /* Ensure it uses the available space */
  align-self: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.file-upload-box {
  border: 2px dashed #CCCCCC;
  padding: 30px;
  margin: 20px 0;
  cursor: pointer;
}

.upload-icon {
  margin-bottom: 10px;
  width: 50px; 
}

.document-icon {
  margin-bottom: 50px;
  margin-top: 50px;
}

.file-info {
  text-align: center; 
  margin-top: 10px; 
}

.browse-link {
  cursor: pointer;
  color: #00BABC; 
  margin-top: 5px; 
}

#validate-button {
  background-color: #00BABC;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#validate-button:hover {
  background-color: #009DA9;
}

#feedback-message {
  color: #00BABC; 
  margin-top: 10px;
}

.loader {
  border: 4px solid #f3f3f3; 
  border-top: 4px solid #3498db; 
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal-background {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center; 
  align-items: center;     
  background-color: rgba(0, 0, 0, 0.5); 
}

